.containerForTag {
  height: auto;
  width: 100%;
  padding: 2vh 0;
  margin-top: 2vh;
  display: flex;
  align-items: center;
  font-size: 15px;
  justify-content: flex-start;

}

.addressTextClass {
  position: relative;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
font-size: 15px;
  width: auto;
  color: #333;

}

.addressTextInput {
  border: 0;
  background-color: transparent;
  font-size: 15px;
 



}


.addressCont {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40px;

}


.addressAbso {
  position: absolute;
  top: -70px;
  text-align: center;
  opacity: 0;

  transition: all 0.3s ease-in-out;
  width: 100px;
  height: 40px;
  padding: 5px;
  background-color: rgb(30, 132, 124);
  /* Set your desired background color */
  border-radius: 20px;
  
  z-index: 8;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);





}

.addressAbso>span {


  z-index: 9;
  color: #fff;
  font-size: 12px;
  

}




.icon {
  cursor: pointer;
  margin-left: 5px;
}





.addressAbso::before,
.addressAbso::after {
  content: '';
  position: absolute;
  border-style: solid;
 
}

.addressAbso::before {
  border-width: 12px 16px 12px 0;
  border-color: transparent rgb(30, 132, 124) transparent transparent;
  top: 100%;
  bottom: -12px;
  margin-top: -20px;
  margin-left: 1px;

  z-index: -1;
}

.addressAbso::after {
  border-width: 0 0 0 0;
  border-color: transparent transparent transparent;
  top: 70%;
  bottom: -14px;
  margin-top: -10px;
}


@media (max-width: 1050px) {

  .container {
    font-size: 16px;

    width: 100%;
    justify-content: center;


  }

  .addressTextClass {

    width: 100%;
    flex-direction: column;
    word-wrap: break-word;
    align-items: center;

    padding-bottom: 2vh;
  }

  .addressText {
    border: 0;
    background-color: transparent;
    width: 80%;

    text-align: center;
    font-size: 15px;
    font-weight: 200;
    word-wrap: break-word;

  }


  .addressAbso {

    display: none;
  }

}

@media (max-width: 600px) {

  .addressTextInput {
    border: 0;
    background-color: transparent;
    width: 80%;

    font-size: 15px;


  }




}