.containerForTable {
    width: 100%;
   
    height: auto;

    background: #fff;

   
    
    transition: all 1s ease-in-out;
   
  
   
}

table {
    padding: 0!important;
    border-radius: 10px;
    
    
}


tbody > tr:nth-child(odd) {
    background: #efeeee;
}


td, th {
    padding: 1vw;
   
    text-align: center;
    font-size: 13px;
    border: 0!important;
    margin: 0!important;
    white-space: nowrap;

   
}


th {
    font-size: 13px;
}

#eventName {
    background: #f8ec85;
    font-weight: 300;
    border:0.25px #222 solid;

}


.specialTD {
 
    font-weight: 500;
    position: relative;
}

.negativeSpan {
    position: absolute;
    top: 5; /* Added 'px' unit */
    text-align: center;
    z-index: 99;
    background: #fff;
    color: rgb(196, 81, 81);
    font-weight: 500;
    padding: 7px;
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    align-items: center;
    justify-content: center;

    height: auto;
    width: 200px;
    transition: all 0.2s ease-in-out;
    opacity: 0;
}

.negativeSpan > p {
    text-align: center;
    width: 90%;
}



.link > a {
    text-decoration: none;
    color: rgb(30, 132, 124);
    font-weight: 500;
    
    transition: all 0.1s ease-in-out;
}

.link > a:hover {
    color: rgb(107, 195, 188);
    cursor: pointer;

}


@media (max-width: 600px) {


    td, th {
        
        font-size: 15px;
       
       
    }






}


   








