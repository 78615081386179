.container {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5vh 0;
   
  }
  
  .wrapper {
  
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    
    padding-top: 4vh;

    
  
    
  }
  
  .box {
    width: 22%;
    min-height: auto;
    height: auto;
    padding: 2vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 0.05px solid #222;
    background: #fff;
    gap: 1.3rem;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-weight: 400;
    
  
  }

  #middleBox {
    margin: 0 2vw;
  }


  .box1 {
    width: auto;
    min-height: 15px;
    height: auto;
    padding: 1vh 3vh;
    display: flex;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    border: 0.05px solid #222;
    align-items: center;
    
    justify-content: center;
    background: #ffffff;
   
  gap: 5rem;
    border-radius: 20px;
    font-weight: 400;
    margin-bottom: 1.5vh;
    
  
  }




 
  
  .box >  h3 {
    font-size: 20px;
    text-align: center;
    width: 80%;
    
    font-weight: 500;
    
  }
  
  .box > input  {
    font-size: 17px;
    font-weight: 300;
    width: 70%;
  }

  .box >  h5 {
    font-size: 17px;
    text-align: center;
    font-weight: 300;
    word-wrap: break-word;
    width: 60%;
  
    
  }

  .box > h5 > span {
    font-size: 15px;
  }


  .box1 >  h5 {
    font-size: 15px;
    text-align: center;
    font-weight: 300;
   
    word-wrap: break-word;
   
  
    
  }


  .box1 >  h5 > span {
    color: rgb(21, 149, 77);
    font-weight: 500;
    font-size: 15px;
  }




  .box >  h5 > span {

  font-weight: 300;
  font-size: 15px;


  }
  
  .box >  button {
  
    background: rgb(30, 132, 124);
    color: #fff;
    font-weight: 200;
    font-size: 11px;
    border-radius: 20px;
    text-transform: uppercase;
    padding: 12px;
    transition: all 0.2s ease-in-out;
    border: 0px;
  
  }
  
  .box > button:hover {
    background: #333 !important;
    cursor: pointer !important;
    color: #fff !important;
  
  
  }


  .marginPut {
    margin-bottom: 2vh;
  }


  .connect {
    margin: 1vh 0;
  }


  
.buttonCont {
  width: 100%;
  height: auto;
  padding: 2vh 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

}

.buttonCont > button {

  background: rgb(30, 132, 124);
  color: #fff;
  font-weight: 200;
  font-size: 12px;
  border-radius: 20px;
  
  text-transform: uppercase;
  padding: 12px;
  transition: all 0.2s ease-in-out;
  border: 0px;
}


.buttonCont > button:hover {
  background: #333 !important;
  cursor: pointer !important;
  color: #fff !important;


}


  
 .lonerButton {
  background: rgb(21, 149, 77);
  color: #fff;
  font-weight: 200;
  font-size: 18px;
  text-transform: uppercase;
  padding: 10px;
  transition: all 0.2s ease-in-out;
  border: 0px;
  margin: 4vh 0;

  margin-bottom: 2vh;
  
  border-radius: 20px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  width: 18%;

 }

 .lonerButton:hover {

  background: #333 !important;
    cursor: pointer !important;
    color: #fff !important;

 }

 #buttonId {
  display: none;
  background-color: rgb(21, 149, 77) ;
  margin: 0;
 }

 .specialH5 {
  font-weight: 500 !important;
 color: rgb(30, 132, 124);
 font-size: 16px !important;
 }


 .specialH5 > span {
  color: #222;
  font-weight: 300;
  font-size: 17px;
 }





  .balances {
    border: 0.5px solid #222;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:1rem;
    font-size: 17px;
  }


  .balances > legend {
    font-weight: 300;
    text-align: center;

    font-size: 17px;
    
    padding: 0 0.2vw;
  }


  .balances > h5 > span {

    font-weight: 300;
  
  
    }

  .modalCont {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    padding: 2vh 0;
  }

  .modalCont > h5 {
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    word-wrap: break-word;
    width: 60%;
    font-weight: 300;
    padding-bottom: 1vh;
  }


  .modalCont > p {
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    word-wrap: break-word;
    width: 80%;
    font-weight: 300;
    padding-bottom: 1vh;
  }


  .modalCont > p > ul {
    margin-left: 0.7vw;
    list-style-type: none;
  }


 

  .modalCont > h5 > span {
    font-weight: bold;
  }


  .rebateH5 {

    margin-bottom: 1vh;

  }


.speshModal::-webkit-scrollbar {
  display: none;
  position: relative;
}

  .speshSpan {
    color: green;
    font-weight: 600 !important;
  }

  .modalCont > h3 {
    font-size: 30px;
    text-align: center;
    width: 80%;
    padding-bottom: 0.8vh;
    font-weight: 600;

  }

  .removeIcon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 24px;
    cursor: pointer;
}

  .foundry {
    background-color: rgb(30, 132, 124) !important;

  }

  .fakestETH {
    background-color:  #fcea4f !important;
    color: #222 !important;

  }

  .disconnect {
    background-color: #f8ec85 !important;
    color: #222 !important;


  }
  


  .error {
    font-size: 21px;
    padding: 3vh 0;
   
    height:auto;
    width: 80%;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    color: red;
  }

  .error2 {
    font-size: 21px;
    padding: 0.5vh 0;
    height:auto;
    width: 80%;
    font-weight: 600;
    gap: 0.5rem;
display: none;
align-items: center;
    flex-direction: column;
    justify-content: center;
    color: red;
  }

  .error2 > p , .error > p  {
    text-align: center;
  }


  @media (max-width: 1750px) { 




}


  
  

  @media (max-width: 1050px) {


    .container {
      padding-bottom: 0vh;
    }

    .wrapper {
      flex-direction: column;
      align-items: center;
      padding-bottom: 0vh;
      width: 90%;

      gap: 2rem;
    }


    .box1 {
    flex-direction: column;
    width: 60%;
    gap: 1rem;
    padding: 3vh 3vh;
      
    
    }

    #buttonId {
      display: flex;
     }

     .lonerButton {
      display: none;
     }

    .box {
      width: 70%;
      
      height: auto;
      justify-content: center;
      padding: 3vh 0;
      gap: 1.8rem;

     
    }


    .box > h3 {
      font-size: 18px;
    }

    


    .lonerButton {

      width: 50%;



    }
    

   

    .box > h3 {
      font-size: 20px;
    }

  

  

     

    .error2 {
    
  display: flex;
  font-size: 20px;
    
    }

    .error {
      display: none;
    }
 
        
  }
  

  @media (max-width: 600px) {

    .box {
      font-size: 16px;
      width:80%;
    }

    .box1 {
      width: 70%;
    
    }

   
    .box > h5 {
      font-size: 17px;
    }

    .box1 > h5 {
      font-size: 17px;
    }

    .box > h3 {
      font-size: 18px;
    }

   


    .buttonCont {
      flex-direction: column;
      gap: 1rem;
    }


    



    .box > input {
      width: 80%;
    }

    .modalCont > h5 {
      font-size: 17px;
    
    }
  
  
    .modalCont > p {
      font-size: 17px;

    }


    .error2 {
    
     width: 90%;
        
        }

        
    
       


    



  }