.container {
    height: auto;
    width: 100%;
    padding-top: 2vh;
    padding-bottom: 4vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

  

}

.container > h2 {
    font-weight: 400;
    font-size: 45px;
    width: 100%;
    background: #edf0f0;
    text-align: center;
    margin:  0;
    color:rgb(30, 132, 124);
    font-weight: 500;
    

    
  
}


.container > h2 > img {
    position: absolute;
    z-index: -0.1;
    width: 100px;
    height: 100px;

    top: 30px;

    left: 33%;
    font-weight: 500;
}

.container > h2 > span {
    font-weight: 500;
    color: #7b9b92;

    
}


.container > p {
    font-size: 17px;
   
    padding-top: 2vh;
    color: #333;
}

.container > p > a  {
    text-decoration: none;
    color:   #222;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
}

.container > p > a:hover {
    
    color:#e9d016;
    


}


@media (max-width: 1092px) {

    .container {

        padding-bottom: 2vh;

    }
   .container > p {
    width: 80%;
    text-align: center;
    
   }

   .container > h2 {
   
    font-size: 30px;
    

   

}

.container > h2 > .eth {
   
    font-size: 40px;
   
    
}
        
  }


  @media (max-width: 600px) {

    .container > h2 {
   
        font-size: 30px;
        
    
       
    
    }

    .container > p {

        font-size: 18px;



    }

  }