

* {
  margin: 0;
  padding: 0;
  
}



.app {


  width: 100%;
  height: auto;


  min-height: 120vh;
  display: flex;

  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
 font-family: "Roboto", sans-serif;
 background: rgb(255,255,255);
 background: linear-gradient(90deg, rgba(255,255,255,1) 5%, rgba(229,245,243,1) 89%);

 
 
}



.tableCont {
  width:  90%;
  height: auto;
  overflow-x: scroll;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  
  border: 0.05px solid #222;
  border-radius: 10px;
  margin-bottom: 10vh;


}

.tableCont::-webkit-scrollbar {
  display: none;
  position: relative;
}


.tableText {

  align-items: center;
  width: auto;

}



.buttonCont {
  width: 100%;
  height: auto;
  padding: 1vh 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}

.buttonCont > button {

  background: rgb(30, 132, 124);
  color: #fff;
  font-weight: 200;
  font-size: 14px;
  
  text-transform: uppercase;
  padding: 10px;
  transition: all 0.2s ease-in-out;
  border: 0px;
}


.buttonCont > button:hover {
  background: #333 !important;
  cursor: pointer !important;
  color: #fff !important;


}


.feedbackCont {
  height: auto;
  width: 100%;
}





@media (max-width: 1750px) {
  .tableCont {
  
  
    width: 90%;
  }
      
}

@media (max-width: 1050px) {


  .tableText {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .tableCont {
  
    
    width: 85%;
  }
      
}